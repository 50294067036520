import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '@/views/Homepage.vue'
import login from '@/views/Login.vue'



Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'login',
    component: login
},
// {

//     path: '/ManageUser',
//     name: 'ManageUser',
//     component: () =>
//         import('@/views/ManageUser')
// },

{
    path: '/Home',
    component: Homepage,
    children: [

        {
            path: '/ManageUser',
            name: 'ManageUser',
            component: () =>
                import('@/views/ManageUser')
        },
        {
            path: '/viewUser',
            name: 'viewUser',
            component: () =>
                import('@/components/User/viewUser.vue')
        },
        {
            path: '/createUser',
            name: 'createUser',
            component: () =>
                import('@/components/User/createUser.vue')
        },
        {
            path: '/editUser',
            name: 'editUser',
            component: () =>
                import('@/components/User/editUser.vue')
        },
    ]
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router